import React from 'react';
import gq from './../../assets/gq.jpg';
import grazie from './../../assets/grazia.jpg';
import jolie from './../../assets/jolie.jpg';



interface State {

}

interface Props {

}

export default class Kunden extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="kunden">
             <h3>Bekannt aus</h3>
             <div className="kunden_wrapper">
                <div className="kunden_img_wrapper">
                <img src={gq}></img>
                </div>
                
                <div className="kunden_img_wrapper">
                <img src={grazie}></img>
                </div>

                <div className="kunden_img_wrapper">
                <img src={jolie}></img>
                </div>

             </div>
            </div>
        )
    }
}